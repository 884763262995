<template>
  <div class="login-wrapper">
    <div class="login-container">
      <h3 class="title">智能空地直播</h3>
      <a-form
        class="login-form"
        :model="formState"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
        layout="vertical"
      >
        <a-form-item class="login-form_item">
          <a-input v-model:value="formState.username" placeholder="请输入登陆名称">
            <template #prefix>
              <UserOutlined style="color: #fff" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="login-form_item">
          <a-input v-model:value="formState.password" type="password" placeholder="请输入密码">
            <template #prefix>
              <LockOutlined style="color: #fff" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="login-form_item">
          <a-button
            class="login-form_submit"
            type="primary"
            html-type="submit"
            :disabled="formState.username === '' || formState.password === ''"
          >
            登录
          </a-button>

           <a-button type="primary" class="login-form_v" @click="login_v">
            统一认证平台
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import io from 'socket.io-client';
import { path } from '@/config';
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import api from '@/services';
import Cookies from 'js-cookie';
import bus from '../bus';

export default {
  setup() {
    const store = useStore();

    const router = useRouter();
    const formState = reactive({
      username: '',
      password: ''
    });

    const isCode = ref(0);

    const pcUserLogin = async () => {
      try {
        let { data, success , code } = await api.pcUserLogin(JSON.stringify({ ...formState }));
        isCode.value = code
        if (success) {
          Cookies.set('SS_token', data.token);
          localStorage.setItem('SS_userinfo', JSON.stringify(data));
          initSocket(data);
          router.push('/');
        }
      } catch (error) {
        console.error(error);
      }
    };

    let registerSocket = computed(() => store.state.registerSocket);
    console.log('registerSocket1====', registerSocket);
    // 初始化 socket
    const initSocket = data => {
      // http://172.16.16.90:9091  -- 线上
      // http://10.0.10.172:9091   -- 严彬本地
      store.commit(
        'CHANGE_REGISTER_SOCKET',
        io(path, {
          query: `method=register&userId=${data.id}&userName=${data.userName}&equipment=pc`
        })
      );
    };

    // 获取有权限的 菜单列表 / 功能列表
    const getMenuList = async () => {
      let userId = JSON.parse(localStorage.getItem('SS_userinfo'))?.id;
      let { data, success } = await api.getMenuList({ userId });
      if (success) {
        localStorage.setItem('authMenuList', JSON.stringify(data.menuList));
        localStorage.setItem('fnCodeList', JSON.stringify(data.functionList));
        bus.emit('addRoutes');
      }
    };

    const handleFinish = async () => {
      await pcUserLogin();
      if(isCode.value == 200) {
        await getMenuList();
      }
    };

    const handleFinishFailed = errors => {
      console.log(errors, '=====');
    };

    const login_v = () => {
      location.href = `http://10.45.0.116:8080/epoint-sso-web/rest/oauth2/authorize?client_id=d792ed0a-2bfd-4d39-ab7f-1d066a504f7a&response_type=code
&redirect_uri=http://2.45.177.47:8090`
    }

    return {
      formState,
      handleFinish,
      handleFinishFailed,
      login_v
    };
  },

  components: {
    UserOutlined,
    LockOutlined
  }
};
</script>

<style lang="less" scoped>
@login_bg: '~@imgs/login/login_bg.png';

.login-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url('@{login_bg}') no-repeat center / 100% 100%;

  .login-container {
    padding-top: 20.56vh;
    position: absolute;
    right: 0;
    width: 30.1vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);

    .title {
      font-size: 2.5vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
  }

  .login-form {
    margin-top: 12.4vh;
    padding: 0 4vw;

    &_item {
      width: 22vw;
      // input框
      ::v-deep(.ant-input-affix-wrapper) {
        padding: 11px 25px;
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: transparent;

        .ant-input-prefix {
          margin-right: 1.67vw;
          color: #fff;
        }

        .ant-input {
          border-left: 1px solid #fff;
          padding-left: 1.67vw;
          background-color: transparent;
          font-size: 0.83rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          line-height: 1.15rem;

          &::-webkit-input-placeholder {
            color: #fff;
          }
        }
      }

      ::v-deep(.ant-form-item-children) {
        .login-form_submit,.login-form_v {
          width: 22vw;
          padding: 0 15px;
          height: 45px;
          line-height: 45px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          letter-spacing: 0.5em;
          background: #007fff;
          box-shadow: 0px 2px 20px 0px rgba(16, 58, 100, 0.2);
          border-color: transparent;
          border-radius: 5px;
        }
        .login-form_v {
         margin-top: 20px;
        }
      }
    }
  }
}
</style>
